import ReCAPTCHA from "react-google-recaptcha";
import React, { useEffect, useRef, useState } from "react";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import {
  FullBackground,
  GetStartedPageContainer,
  Layout,
  SEO,
} from "components";
import { device, routes } from "utils";
import { getCaptchaToken } from "../../utils/captchaUtils";
import { navigate } from "gatsby";

const GetStartedContactPage = ({ location }) => {
  let accountType = location.state?.accountType || "Enterprise";
  let hubspotAccountType = accountType;
  switch (hubspotAccountType) {
    case "Academia":
    case "Class":
      hubspotAccountType = "Academic";
      break;
    case "Startup":
      hubspotAccountType = "Start-up";
      break;
    default:
      hubspotAccountType = "Enterprise";
  }

  const [formReady, setFormReady] = useState(false);
  const [hasSubmissionResponse, setHasSubmissionResponse] = useState(false);

  // Check if contact has basic information to display. If not, that means
  // the user navigated to this url directly, so we would like to redirect them
  // back to the Get Started page
  // Note: useEffect throws error in console in local dev, but is fine in compiled preview/prod.
  useEffect(() => {
    if (!location.state?.accountType) {
      console.log("GetStartedContact redirecting to getStartedLanding");
      navigate(routes("getStartedLanding"), { replace: true });
    }
  }, [location.state]);

  useEffect(() => {
    let clickListener = null;
    let submitButton = null;

    const onFormSubmitted = ($form, data) => {
      setHasSubmissionResponse(true);
    };

    const onFormReady = ($form, e) => {
      const segmentInput = $form.querySelector("input[name='segment__c']");
      if (segmentInput) {
        segmentInput.value = hubspotAccountType;
      }

      clickListener = async (event) => {
        event.preventDefault();

        const token = await getCaptchaToken(recaptchaRef);
        if (!token) {
          return;
        }

        $form.submit();
      };

      submitButton = document.querySelector("[type='submit']");
      submitButton.addEventListener("click", clickListener, false);

      setFormReady(true);
    };

    const loadHubspot = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "45776621",
          formId: "60af8d83-0d9b-4248-b1c4-55f516512549",
          sfdcCampaignId: "701Vs000006SiibIAC",
          target: "#hs-form-container",
          onFormReady,
          onFormSubmitted,
        });
      }
    };

    if (window.hbspt) {
      loadHubspot();
    } else {
      const script = document.createElement("script");
      script.src = "https://js.hsforms.net/forms/embed/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        loadHubspot();
      });
    }

    return () => {
      if (clickListener && submitButton) {
        submitButton.removeEventListener("click", clickListener, false);
      }
    };
  }, [hubspotAccountType]);

  useEffect(() => {
    try {
      window.gtag("event", "conversion", {
        send_to: "AW-441213032/Bu2SCJmL7_IBEOjAsdIB",
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const recaptchaRef = useRef(null);

  const title = "Get Started with ECL | Contact";
  const description = "Get your team experimenting in the cloud today";

  return (
    <FullBackground>
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <GetStartedPageContainer
          state={{
            accountType,
          }}
          step="contact"
          showHeader
        >
          {formReady && !hasSubmissionResponse && (
            <>
              <S.Row>
                <S.Col className="section-header">
                  Interested to learn more about the hundreds of thousands of
                  experiments running in cloud lab facilities?
                </S.Col>
              </S.Row>
              <S.Row>
                <S.Col className="section-header-sub">
                  Please answer a few questions so we can learn more about your
                  research needs. A cloud lab specialist will reach out to
                  discuss how ECL can help you achieve your major wet laboratory
                  research milestones within your budget and quicker than you
                  ever thought possible!{" "}
                </S.Col>
              </S.Row>
            </>
          )}

          <S.HubspotForm id="hs-form-container"></S.HubspotForm>

          <S.Col className="inquiry-contact">
            For all other inquiries, please contact
            <a
              href="mailto:sales@emeraldcloudlab.com"
              target="_blank"
              rel="noreferrer"
            >
              sales@emeraldcloudlab.com
            </a>
          </S.Col>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
            theme="dark"
          />
        </GetStartedPageContainer>
      </Layout>
    </FullBackground>
  );
};

export default GetStartedContactPage;

const S = {};

S.Row = styled(Row)``;

S.Col = styled(Col)`
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2rem;
  // border: 1px solid magenta;
  padding-bottom: 3rem;
  &.section-header {
    color: #ffffff;
    font-size: 2.4rem;
    line-height: 3rem;
    // border: 1px solid yellow;
  }
  &.section-header-sub {
    font-weight: 300;
    color: #ffffff;
  }
  &.fine-print {
    color: #b1bac2;
    font-weight: 300;
    &.final {
      color: #768594;
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
  &.single-item {
    display: flex;
    flex-direction: column;
  }
  &.comments {
    padding-bottom: 2rem;
  }
  .error-message {
    color: #e95e5e;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1.7rem;
    margin-top: 0.2rem;
    &.button {
      white-space: nowrap;
      @media all and ${device.md} {
        margin: 1rem 0;
      }
    }
  }
  &.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    @media all and ${device.md} {
      flex-direction: row;
    }
    padding-bottom: 1rem;
  }
  &.inquiry-contact {
    color: rgb(177, 186, 194);
    font-size: 1.4rem;
    font-weight: 300;
    a {
      margin-left: 3px;
      font-size: 1.4rem;

      cursor: pointer;
      color: rgb(12, 189, 150);

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

S.HubspotForm = styled.div`
  color: ${({ theme }) => theme.white};

  .hs-form-field {
    margin-bottom: 1.7rem;
  }

  .actions > [type="submit"] {
    margin: 1.6rem 0;
    border-color: transparent;
    border-radius: 0;
    border: 0;
    font-size: 1.4rem;
    height: 3.2rem;
    /* padding: 0 2.1rem; */
    text-transform: uppercase;

    color: ${({ theme }) => theme.white};
    &:active {
      background-color: ${({ theme }) => theme.greenDarker};
      border-color: transparent;
    }
    &:hover {
      background-color: ${({ theme }) => theme.greenDarkerHover};
      border-color: transparent;
    }

    background-color: ${({ theme }) => theme.greenDarker};

    /* padding: 0; */
    margin: 1rem 0;

    @media all and ${device.sm} {
      width: 11.5rem;
    }
  }
`;
